const basePath = "/images/tokens/";

export const OtherTokens = [
	{
        symbol: "TEN",
        contract: "0x9833c643f387ecFB76AA8114546AD524703c66Fb",
		coingeckoId: "teneo",
		isTenToken: false,
		imagePath: basePath + "ten.svg",
		chain: "ftm"
    },
    {
        symbol: "DEI",
        contract: "0xde12c7959e1a72bbe8a5f7a1dc8f8eef9ab011b3",
		coingeckoId: "dei-token",
		isTenToken: false,
		imagePath: basePath + "dei.svg",
		chain: "ftm"
    },
	{
        symbol: "SPIRIT",
        contract: "0x5cc61a78f164885776aa610fb0fe1257df78e59b",
		coingeckoId: "spiritswap",
		isTenToken: false,
		imagePath: basePath + "spirit.svg",
		chain: "ftm"
    }
];