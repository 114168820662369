const basePath = "/images/tokens/";

export const Tokens = [
    {
        symbol: "BUSD",
        contract: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
        amm: "0xA1d2b60a7938108Fd0b8884d91cF14F99566423E",
		decimals: 18,
		coingeckoId: "binance-usd",
		isTenToken: false,
		imagePath: basePath + "busd.svg",
		chain: "bsc",
        swap: ["tenBUSD"],
    },
    {
        symbol: "tenBUSD",
        contract: "0xCb870e03fBCad0e8e70d13CE24Cd23E9f78E7D2f",
        amm: "0xA1d2b60a7938108Fd0b8884d91cF14F99566423E",
		decimals: 22,
		creationTime: 1645880291,
		coingeckoId: "binance-usd",
		isTenToken: true,
		imagePath: basePath + "busd.svg",
		chain: "bsc",
        swap: ["BUSD"],
    },
	{
        symbol: "TEN",
        contract: "0x25B9d4b9535920194c359d2879dB6a1382c2ff26",
        amm: "0xa0DE34181619aC4723168bf63Ff370eC47bAA834",
		decimals: 18,
		coingeckoId: "teneo",
		isTenToken: false,
		imagePath: basePath + "ten.svg",
		chain: "bsc",
        swap: ["tenTEN"],
    },
    {
        symbol: "tenTEN",
        contract: "0xAb887Edaf53570dd212E94CecdA38B7c165420b5",
        amm: "0xa0DE34181619aC4723168bf63Ff370eC47bAA834",
		decimals: 22,
		creationTime: 1645803059,
		coingeckoId: "teneo",
		isTenToken: true,
		imagePath: basePath + "ten.svg",
		chain: "bsc",
        swap: ["TEN"],
    },
	{
        symbol: "FTM",
		wrappedContract: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
        amm: "0x8a5aF141828236D256AC06bE93d4655C02b99780",
		decimals: 18,
		coingeckoId: "fantom",
		isTenToken: false,
		imagePath: basePath + "ftm.svg",
		chain: "ftm",
        swap: ["tenFTM"],
    },
    {
        symbol: "tenFTM",
        contract: "0x8106014b407BD08dB9AfAC50741571735ff443f9",
        amm: "0x8a5aF141828236D256AC06bE93d4655C02b99780",
		decimals: 22,
		creationTime: 1649941200,
		coingeckoId: "fantom",
		isTenToken: true,
		imagePath: basePath + "ftm.svg",
		chain: "ftm",
        swap: ["FTM"],
    },
	{
        symbol: "OWL",
		contract: "0x9085b4d52c3e0b8b6f9af6213e85a433c7d76f19",
        amm: "0x3ae98a445CC5f7aa3e2AEEd03dF4AF8a52C2765e",
		decimals: 18,
		coingeckoId: "owldao",
		isTenToken: false,
		imagePath: basePath + "owl.svg",
		chain: "bsc",
        swap: ["tenOWL"],
    },
    {
        symbol: "tenOWL",
        contract: "0x58F11bD9A89A36eb6d757C415EAaf19DDA044c3e",
        amm: "0x3ae98a445CC5f7aa3e2AEEd03dF4AF8a52C2765e",
		decimals: 22,
		creationTime: 1652356800,
		coingeckoId: "owldao",
		isTenToken: true,
		imagePath: basePath + "owl.svg",
		chain: "bsc",
        swap: ["OWL"],
    },
    {
        symbol: "BNB",
		wrappedContract: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        amm: "0x896310cF25af7e70Bdf4c5b35ddf14719fb28715",
		decimals: 18,
		coingeckoId: "binancecoin",
		isTenToken: false,
		imagePath: basePath + "bnb.svg",
		chain: "bsc",
        swap: ["tenBNB"],
    },
    {
        symbol: "tenBNB",
        contract: "0xfE55EaD876A85b84E125De26fF5086d7eB99BD14",
        amm: "0x896310cF25af7e70Bdf4c5b35ddf14719fb28715",
		decimals: 22,
		creationTime: 1645534800,
		coingeckoId: "binancecoin",
		isTenToken: true,
		imagePath: basePath + "bnb.svg",
		chain: "bsc",
        swap: ["BNB"],
    },
	{
        symbol: "BTCB",
        contract: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
        amm: "0xDb3F82966875FfA612b8756F6d6EB97EDc744586",
		decimals: 18,
		coingeckoId: "binance-bitcoin",
		isTenToken: false,
		imagePath: basePath + "btcb.svg",
		chain: "bsc",
        swap: ["tenBTCB"],
    },
    {
        symbol: "tenBTCB",
        contract: "0xA01BE845f95447110082797267AeCcA1DE4047BF",
        amm: "0xDb3F82966875FfA612b8756F6d6EB97EDc744586",
		decimals: 22,
		creationTime: 1646067480,
		coingeckoId: "binance-bitcoin",
		isTenToken: true,
		imagePath: basePath + "btcb.svg",
		chain: "bsc",
        swap: ["BTCB"],
    },
	{
        symbol: "ETH",
        contract: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
        amm: "0x0dc95dAEa8929Fe82740B290Ca439aC9A4fb1649",
		decimals: 18,
		coingeckoId: "ethereum",
		isTenToken: false,
		imagePath: basePath + "eth.svg",
		chain: "bsc",
        swap: ["tenETH"],
    },
    {
        symbol: "tenETH",
        contract: "0x5D19Dd1482B7d2262327bd7a4Dc88862a4D4a2CD",
        amm: "0x0dc95dAEa8929Fe82740B290Ca439aC9A4fb1649",
		decimals: 22,
		creationTime: 1646146680,
		coingeckoId: "ethereum",
		isTenToken: true,
		imagePath: basePath + "eth.svg",
		chain: "bsc",
        swap: ["ETH"],
    },
	{
        symbol: "LINK",
        contract: "0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd",
        amm: "0x1D7aa8cAEFcb77583490e47f18786775F6a20edE",
		decimals: 18,
		coingeckoId: "chainlink",
		isTenToken: false,
		imagePath: basePath + "link.svg",
		chain: "bsc",
        swap: ["tenLINK"],
    },
    {
        symbol: "tenLINK",
        contract: "0xD1aaAc12a27546C10cAb52DE8DeA7a451b8ad040",
        amm: "0x1D7aa8cAEFcb77583490e47f18786775F6a20edE",
		decimals: 22,
		creationTime: 1647522600,
		coingeckoId: "chainlink",
		isTenToken: true,
		imagePath: basePath + "link.svg",
		chain: "bsc",
        swap: ["LINK"],
    },
	{
        symbol: "CAKE",
        contract: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
        amm: "0xfB30680B230a9eC25c8A3af547d705989be5D3a9",
		decimals: 18,
		coingeckoId: "pancakeswap-token",
		isTenToken: false,
		imagePath: basePath + "cake.svg",
		chain: "bsc",
        swap: ["tenCAKE"],
    },
    {
        symbol: "tenCAKE",
        contract: "0x98Dc696e34600C09FE74d84DB1Fc57f13E33DA10",
        amm: "0xfB30680B230a9eC25c8A3af547d705989be5D3a9",
		decimals: 22,
		creationTime: 1646312340,
		coingeckoId: "pancakeswap-token",
		isTenToken: true,
		imagePath: basePath + "cake.svg",
		chain: "bsc",
        swap: ["CAKE"],
    },
    {
        symbol: "DEI",
        contract: "0xde12c7959e1a72bbe8a5f7a1dc8f8eef9ab011b3",
        amm: "0x4CeD8E4f3e1522246B5547B2DDEBC436F4E50258",
		decimals: 18,
		coingeckoId: "dei-token",
		isTenToken: false,
		imagePath: basePath + "dei.svg",
		chain: "bsc",
        swap: ["tenDEI"],
    },
    {
        symbol: "tenDEI",
        contract: "0x6D82ACBB502cC22905E17A9c4da8D4A8B743305B",
        amm: "0x4CeD8E4f3e1522246B5547B2DDEBC436F4E50258",
		decimals: 22,
		creationTime: 1645534800,
		coingeckoId: "dei-token",
		isTenToken: true,
		imagePath: basePath + "dei.svg",
		chain: "bsc",
        swap: ["DEI"],
    },
];