import { createContext, useState, useEffect } from 'react';
import { getAccount } from "../../lib/web3/web3Base.js";

export const IsConnectedContext = createContext();

export function IsConnectedProvider({children}) {
	const [isConnected, setIsConnected] = useState(undefined);

	useEffect(() => {
		getAccount().then(account => {
			setIsConnected(!!account);
		});
	}, []);

	return (
		<IsConnectedContext.Provider value={isConnected}>
			{children}
		</IsConnectedContext.Provider>
	);
}
