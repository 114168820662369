export const Contracts = {
	staking: {
		bsc: "0xDfE35EFf0ee772c624eDa781B1A1904e1b16bC14",
		ftm: "0xfb594654960874FC2bE33C4FDDA9c91F0E820dd1"
	},
	zapper: {
		bsc: "0x7eface138b43a760f0f248ce990a4a10f320bd67",
		ftm: "0xf5099C944324cd87eFDcAB89d4C020C0A7ec6191",
	},
	nfts: {
		bsc: {
			minter: "0x4A96D8b84A3804566b8218A022cD023fEd14B346",
			nft: "0x46363c3CF050e073B9D23882E4A65968EDCBe341",
			payToken: "0x6B30D83b85722425CE6e668ba00D597f52Db64ea"
		}
	}
};