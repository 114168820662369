import { createContext, useContext, useState, useEffect } from 'react';
import { CoingeckoPricesContext } from "./CoingeckoPricesProvider.js";
import { getLPTokenValue } from '../../lib/web3/web3Staking.js';

import { Pools } from "../../data/pools.js";

export const LpTokenValuesContext = createContext();

export function LpTokenValuesProvider({children}) {
	const [lpTokenValues, setLpTokenValues] = useState(undefined);

	const coingeckoPrices = useContext(CoingeckoPricesContext);

	// ---- HOOKS ----

	useEffect(() => {
		if (coingeckoPrices !== undefined) {
			getAllLpTokenValues().then((result) => {
				setLpTokenValues(result);
			});
		}
	}, [coingeckoPrices]);

	// ---- FUNCTIONS ----

	async function getAllLpTokenValues() {
		let results = await Promise.all(Pools.map(async pool => {
			let lpTokenValue = await getLPTokenValue(pool, coingeckoPrices);
			
			return {
				contract: pool.stakeToken.contract,
				lpTokenValue: lpTokenValue
			};
		}));

		let lpTokenValues = {};

		for (const result of results) {
			lpTokenValues[result.contract] = result.lpTokenValue;
		}

		return lpTokenValues;
	}

	return (
		<LpTokenValuesContext.Provider value={lpTokenValues}>
			{children}
		</LpTokenValuesContext.Provider>
	);
}
