import styles from "./Modal.module.scss";
import { requestAccount, isMetaMaskConnected } from "../../lib/web3/web3Base.js";

export default function Modal({closeModal}) {
	const metaMaskMessage = "you can only disconnect metamask yourself via the browser-extension";

	// ---- HANDLE BUTTONS ----

	function handleWalletConnect() {
		if (!isMetaMaskConnected()) {
			alert(metaMaskMessage);
		} else {
			requestAccount();
		}

		closeModal();
    }

    return (
        <div className={styles.container}>
			<div className={styles.text}>By clicking the button below, you are accepting our <a href="https://teneo.finance/termsofuse.pdf">terms of use</a>.</div>

			<hr/>

			<button type="button" className={"w-button button-blue accept-button " + styles.button} onClick={handleWalletConnect}>Accept</button>
		</div>
    );
}