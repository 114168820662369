import { Tokens } from '../../data/tokens.js';
import { OtherTokens } from "../../data/otherTokens.js";

const baseUrl = "https://api.coingecko.com/api/v3";

export async function getTokenPrices() {
	let coingeckoIds = Tokens.concat(OtherTokens).map(token => token.coingeckoId);
	let filtered = coingeckoIds.filter(id => id !== "");

	let unique = [...new Set(filtered)];
	let ids = unique.join("%2C");

	let url = baseUrl + "/simple/price/?vs_currencies=usd&ids=" + ids;
	let response = await fetch(url);
	let json = await response.json();

	let prices = {};
	for (const key in json) {
		prices[key] = json[key].usd;
	}

	return prices;
}