import {getToken} from "./../lib/helper.js";

export const Pools = [
	{
		orderId: 1,
		id: 8,
		stakeToken: {
			contract: '0xe7880ce67C1a03fBDB5ad8A44a8aD634753482F7',
			decimals: 18,
		},
		tokenA: getToken("bsc", "TEN"),
		tokenB: getToken("bsc", "tenBUSD"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 2,
		id: 9,
		stakeToken: {
			contract: '0x53A719E1d1959F6DDaA55e55A9fF339793a824e8',
			decimals: 18,
		},
		tokenA: getToken("bsc", "BNB"),
		tokenB: getToken("bsc", "tenBUSD"),
		zapToken: getToken("bsc", "BNB"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 3,
		id: 6,
		stakeToken: {
			contract: '0xA9E47AD4C989A393Ea53b23d3D47FAa78077FA63',
			decimals: 18,
		},
		tokenA: getToken("bsc", "TEN"),
		tokenB: getToken("bsc", "tenTEN"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 4,
		id: 7,
		stakeToken: {
			contract: '0x11a895C460891f0408aCDe1d28C7a21B02bcfE11',
			decimals: 18,
		},
		tokenA: getToken("bsc", "BUSD"),
		tokenB: getToken("bsc", "tenTEN"),
		zapToken: getToken("bsc", "BUSD"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 5,
		id: 18,
		stakeToken: {
			contract: '0xefdB8fac0a3A28508187BC961Ea50E8c848DaC4f',
			decimals: 18,
		},
		tokenA: getToken("bsc", "TEN"),
		tokenB: getToken("bsc", "tenOWL"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 6,
		id: 19,
		stakeToken: {
			contract: '0x00FE782B9345a1F84826c85CFD05c91645185B4e',
			decimals: 18,
		},
		tokenA: getToken("bsc", "BUSD"),
		tokenB: getToken("bsc", "tenOWL"),
		zapToken: getToken("bsc", "BUSD"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 7,
		id: 0,
		stakeToken: {
			contract: '0xd690bE2B4B1F89ab7a0425B678a7746955C0739d',
			decimals: 18,
		},
		tokenA: getToken("ftm", "TEN"),
		tokenB: getToken("ftm", "tenFTM"),
		chain: "ftm",
		mode: 2,
	},
	{
		orderId: 8,
		id: 1,
		stakeToken: {
			contract: '0x7073623533689175AB1a240bbaA234C0156b1627',
			decimals: 18,
		},
		tokenA: getToken("ftm", "DEI"),
		tokenB: getToken("ftm", "tenFTM"),
		zapToken: getToken("ftm", "DEI"),
		chain: "ftm",
		mode: 2,
	},
	{
		orderId: 9,
		id: 2,
		stakeToken: {
			contract: '0x6f05ee59784818b7Cc1Be9618F54AfdE8E61bb35',
			decimals: 18,
		},
		tokenA: getToken("ftm", "SPIRIT"),
		tokenB: getToken("ftm", "tenFTM"),
		zapToken: getToken("ftm", "SPIRIT"),
		chain: "ftm",
		mode: 2,
	},
	{
		orderId: 10,
		id: 0,
		stakeToken: {
			contract: '0x73f7a21752F89A7EA4b95A76491Cf3bDe1b9ccfe',
			decimals: 18,
		},
		tokenA: getToken("bsc", "TEN"),
		tokenB: getToken("bsc", "tenBNB"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 11,
		id: 1,
		stakeToken: {
			contract: '0x7AbE69f9710d167b8EDC4296Ec40f1d4907D3f74',
			decimals: 18,
		},
		tokenA: getToken("bsc", "BUSD"),
		tokenB: getToken("bsc", "tenBNB"),
		zapToken: getToken("bsc", "BUSD"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 12,
		id: 10,
		stakeToken: {
			contract: '0x35Ab9225d36A91b76E40F888bF8Ea7F9ebEf076e',
			decimals: 18,
		},
		tokenA: getToken("bsc", "TEN"),
		tokenB: getToken("bsc", "tenBTCB"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 13,
		id: 11,
		stakeToken: {
			contract: '0x2Bc046e5f2A83d90818CDcE5f43e29b2a88647f9',
			decimals: 18,
		},
		tokenA: getToken("bsc", "BUSD"),
		tokenB: getToken("bsc", "tenBTCB"),
		zapToken: getToken("bsc", "BUSD"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 14,
		id: 12,
		stakeToken: {
			contract: '0x52c848C90eA4126013b8016B0F3bDAAdd811e1de',
			decimals: 18,
		},
		tokenA: getToken("bsc", "TEN"),
		tokenB: getToken("bsc", "tenETH"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 15,
		id: 13,
		stakeToken: {
			contract: '0xe1CE7d483936820e80c5E3b58C037f166E6cA49F',
			decimals: 18,
		},
		tokenA: getToken("bsc", "BUSD"),
		tokenB:getToken("bsc", "tenETH"),
		zapToken: getToken("bsc", "BUSD"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 16,
		id: 16,
		stakeToken: {
			contract: '0x215E6311FB83824D440078792c2942D0B72a7D63',
			decimals: 18,
		},
		tokenA: getToken("bsc", "TEN"),
		tokenB: getToken("bsc", "tenLINK"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 17,
		id: 17,
		stakeToken: {
			contract: '0x89e09E6a32402C44B486DEE241772fa3F8Eb7c69',
			decimals: 18,
		},
		tokenA: getToken("bsc", "BUSD"),
		tokenB: getToken("bsc", "tenLINK"),
		zapToken: getToken("bsc", "BUSD"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 18,
		id: 14,
		stakeToken: {
			contract: '0x2a4a93Eb104223B5b319eA5244651C3d13Af9338',
			decimals: 18,
		},
		tokenA: getToken("bsc", "TEN"),
		tokenB: getToken("bsc", "tenCAKE"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 19,
		id: 15,
		stakeToken: {
			contract: '0xC21E6F15671f7113Cf50eb59458A4F9F7100f298',
			decimals: 18,
		},
		tokenA: getToken("bsc", "BUSD"),
		tokenB: getToken("bsc", "tenCAKE"),
		zapToken: getToken("bsc", "BUSD"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 20,
		id: 2,
		stakeToken: {
			contract: '0x31237D0485133Bc4f8f25c951154cdD88271d122',
			decimals: 18,
		},
		tokenA: getToken("bsc", "TEN"),
		tokenB: getToken("bsc", "tenDEI"),
		chain: "bsc",
		mode: 2,
	},
	{
		orderId: 21,
		id: 4,
		stakeToken: {
			contract: '0x9C30C2634E4721eFCe64d62dbF3ddDa1064E756f',
			decimals: 18,
		},
		tokenA: getToken("bsc", "BNB"),
		tokenB: getToken("bsc", "tenDEI"),
		zapToken: getToken("bsc", "BNB"),
		chain: "bsc",
		mode: 2,
	},
];