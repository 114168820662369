import { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import styles from "./Header.module.scss";
import Background from "../common/Background.js";
import Modal from "./Modal.js";
import { IsConnectedContext } from "../contexts/IsConnectedProvider.js";
import { getAccount, getChainName, getChainId, switchChain } from "../../lib/web3/web3Base.js";

import { getChains } from "../../data/chains.js";

export default function Header() {
	const navigate = useNavigate();
	const location = useLocation();

	const isConnected = useContext(IsConnectedContext);

	const [isScript, setIsScript] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [connectButtonText, setConnectButtonText] = useState("Connect Wallet");
	const [chainId, setChainId] = useState(undefined);
	const [chainName, setChainName] = useState(undefined);
	const [isChainImage, setIsChainImage] = useState(true);

	// ---- HOOKS ----

	useEffect(() => {
		if (!isScript) {
			const script1 = document.createElement("script");

			script1.src = "https://code.jquery.com/jquery-3.7.0.min.js";
			script1.integrity = "sha256-2Pmvv0kuTBOenSvLm6bvfBSSHrUJ+3A7x6P5Ebd07/g=";
			script1.crossOrigin = "anonymous";
			script1.onload = () => { setIsScript(true); };

			document.body.appendChild(script1);
		}
	}, []);

	useEffect(() => {
		if (isScript) {
			const script2 = document.createElement("script");
		
			script2.src = "/js/webflow.js";
	
			document.body.appendChild(script2);
		}
	}, [isScript]);

	useEffect(() => {
		setIsModalOpen(false);
	}, [location]);

	useEffect(() => {
		refreshChain();
		refreshAccount();
	}, [isConnected]);

	// ---- FUNCTIONS ----

	function refreshChain() {
		if (isConnected === true) {
			Promise.all([
				getChainId(),
				getChainName()
			]).then((values) => {
				setChainId(values[0]);
				setChainName(values[1]);
			});
		}
	}

	function refreshAccount() {
		if (isConnected === true) {
			getAccount().then((account) => {
				let accountText = account.substring(0, 6) + "..." + account.substring(account.length - 4, account.length);

				setConnectButtonText(accountText);
			});
		}
	}

	// ---- HANDLE BUTTONS ----

	function handleConnectButton() {
		if (!isConnected) {
			setIsModalOpen(true);
		}
	}

	function handleSwitchChainButton(chain) {
		if (isConnected === true) {
			switchChain(chain);
		}
	}

	// ---- HTML ELEMENTS ----

	function handleRouteChange(event, path) {
		event.preventDefault();

		if (document.getElementsByClassName("w-nav-overlay")[0].style.display === "block") {
			document.getElementsByClassName("menu-button w-nav-button")[0].click();
		}

		document.querySelectorAll("a[href='" + window.location.pathname + "']")[0].classList.remove("w--current");

		navigate(path);

		document.querySelectorAll("a[href='" + path + "']")[0].classList.add("w--current");
	}

	function getChainButtons() {
		let chains = getChains();

		const chainButtons = chains.map((chain, index) => {
			let isDisabled = !isConnected || (chainId === chain.id);
			let title = !isConnected ? "please connect your wallet first" : undefined;

			return (
				<button key={index} className={styles.dropdown_button + " " + styles.chain_image_container} onClick={() => handleSwitchChainButton(chain)} disabled={isDisabled} title={title}>
					<img className={styles.chain_image} src={"/images/chains/" + chain.nameId + ".svg"} alt={"/images/chains/" + chain.nameId + ".svg"} />
					<span>{chain.nameId.toUpperCase()}</span>
				</button>
			);
		});

		return chainButtons;
	}

	return (
		<div data-collapse="medium" data-animation="over-right" data-duration="400" role="banner" className="navbar w-nav">
			<Background isActive={isModalOpen} onClick={() => setIsModalOpen(false)}/>

			<div className="nav-container">
				<a href="/" aria-current="page" className="brand w-nav-brand" onClick={(event) => handleRouteChange(event, "/")}>
					<img src="/images/Teneo_Logo_red.svg" loading="lazy" alt="" className="teneo-logo"/>
				</a>

				<nav role="navigation" className={"nav-menu w-nav-menu " + styles.nav_menu_extra}>
					<a href="/swap" className="nav-link w-nav-link" onClick={(event) => handleRouteChange(event, "/swap")}>Swap</a>
					<a href="/farm" className="nav-link w-nav-link" onClick={(event) => handleRouteChange(event, "/farm")}>Farm</a>
					<a href="/compound" className="nav-link w-nav-link" onClick={(event) => handleRouteChange(event, "/compound")}>Compound</a>

					<a href="/pdfs/litepaper.pdf" target="_blank" rel="noopener noreferrer" className="docs w-nav-link">Litepaper</a>
					<a href="https://youtu.be/PHs75DJfgJ0" target="_blank" rel="noopener noreferrer" className="docs w-nav-link">dApps Tutorial</a>
					<a href="https://teneofinance.gitbook.io/teneo-finance" target="_blank" rel="noopener noreferrer" className="docs w-nav-link">Gitbook</a>
					<a href="/pdfs/pitchdeck.pdf" target="_blank" rel="noopener noreferrer" className="docs w-nav-link">Pitchdeck</a>

					<div data-hover="true" data-delay="300" className="dropdown-nav-menu w-dropdown">
						<div className="dropdown-toggle-nav-menu w-dropdown-toggle">
							<div className="icon-3 w-icon-dropdown-toggle"></div>
							<div className="text-block-5">Docs</div>
						</div>

						<nav className="dropdown-list-nav-menu w-dropdown-list">
							<a href="/pdfs/litepaper.pdf" target="_blank" rel="noopener noreferrer" className="dropdown-link-10 w-dropdown-link">Litepaper</a>
							<a href="https://youtu.be/PHs75DJfgJ0" target="_blank" rel="noopener noreferrer" className="dropdown-link-10 w-dropdown-link">dApps Tutorial</a>
							<a href="https://teneofinance.gitbook.io/teneo-finance" target="_blank" rel="noopener noreferrer" className="dropdown-link-10 w-dropdown-link">Gitbook</a>
							<a href="/pdfs/pitchdeck.pdf" target="_blank" rel="noopener noreferrer" className="dropdown-link-10 w-dropdown-link">Pitchdeck</a>
						</nav>
					</div>

					<hr className={styles.not_visible}/>

					<div className={styles.button_mobile_1}>
						<button className={"button-connect tablet-and-below w-button " + styles.connect_button} onClick={handleConnectButton} disabled={isConnected}>{connectButtonText}</button>
					</div>
					
					<div className={styles.button_mobile_container}>
						{getChainButtons()}
					</div>
				</nav>

				<button id="btn-connect-wallet" className={"button-connect desktop-and-above w-button " + styles.connect_button_2} onClick={handleConnectButton} disabled={isConnected}>{connectButtonText}</button>

				<div data-hover="true" data-delay="300" className={"dropdown-nav-menu desktop-and-above w-dropdown " + styles.dropdown}>
					<div className={"dropdown-toggle-nav-menu w-dropdown-toggle " + styles.toggle_extra}>
						<div className="icon-3 w-icon-dropdown-toggle"></div>
						{
							chainName !== undefined
							?
								<div className={styles.chain_image_container}>
									{
										isChainImage === true &&
										<img className={styles.chain_image} src={"/images/chains/" + chainName.toLowerCase() + ".svg"} alt={"/images/chains/" + chainName.toLowerCase() + ".svg"} onError={() => setIsChainImage(false)}/>
									}
									<span>{chainName}</span>
								</div>
							:
								<div>Chains</div>
						}
					</div>

					<nav className={"dropdown-list-nav-menu w-dropdown-list " + styles.dropdown_menu2}>
						{getChainButtons()}
					</nav>
				</div>

				<div className={"menu-button w-nav-button " + styles.menu_button}>
					<div className="icon w-icon-nav-menu"></div>
				</div>
			</div>

			<div className="container w-container"></div>

			{
				isModalOpen &&
				<Modal closeModal={() => {setIsModalOpen(false)}}/>
			}
		</div>
	);
}