import {web3s, provider, getMessage, getCurrentChain, getBalance, fromWei, fromWeiByToken, approve} from './web3Base.js';
import {getToken} from '../../lib/helper.js';

import stakingAbi from '../../data/abis/staking.json';
import zapperAbi from '../../data/abis/zapper.json';
import tokenAbi from '../../data/abis/token.json';

import {Contracts} from '../../data/contracts.js';

// ---- GENERAL ----

async function getCurrentStaking() {
	const chain = await getCurrentChain();
	const stakingContract = new provider.Contract(stakingAbi, Contracts.staking[chain.nameId]);

	return stakingContract;
}

export async function getOwner() {
	const stakingContract = await getCurrentStaking();

	return stakingContract.methods.owner().call();
}

export async function getStakingApy(pool, coingeckoPrices, lpTokenValues) {
	const teneo = getToken(pool.chain, "TEN");

	let totalValueLocked = await getTotalValueLocked(pool, lpTokenValues);
	let poolApy = await getPoolApyByPool(pool);
	let teneoPrice = coingeckoPrices[teneo.coingeckoId];
	let stakeTokenBalanceWei = await getBalance(pool.chain, pool.stakeToken.contract, Contracts.staking[pool.chain]);

	let rewardsPerYear;
	if (stakeTokenBalanceWei === "0") {
		rewardsPerYear = await fromWei(poolApy, 18);
	} else {
		let stakeTokenBalance = await fromWei(stakeTokenBalanceWei, 12);
		let rewardsPerYearWei = poolApy * stakeTokenBalance;

		rewardsPerYear = await fromWei(rewardsPerYearWei, 18);
	}

	return (rewardsPerYear * teneoPrice) / (totalValueLocked / 100);
}

export async function getTotalValueLocked(pool, lpTokenValues) {
	let stakeToken = new web3s[pool.chain].Contract(tokenAbi, pool.stakeToken.contract);
	let totalSupply = await stakeToken.methods.totalSupply().call();

	let stakeTokenBalance = await getBalance(pool.chain, pool.stakeToken.contract, Contracts.staking[pool.chain]);
	stakeTokenBalance = stakeTokenBalance === "0" ? totalSupply : stakeTokenBalance;

	let tvl = await getLPTokenPrice(pool, stakeTokenBalance, lpTokenValues);

	return tvl;
}

export async function getLPTokenPrice(pool, balance, lpTokenValues) {
	let stakeToken = new web3s[pool.chain].Contract(tokenAbi, pool.stakeToken.contract);
	let totalSupply = await stakeToken.methods.totalSupply().call();

	let ratio = balance / totalSupply;
	let lpTokenValue = lpTokenValues[pool.stakeToken.contract];

	return ratio * lpTokenValue;
}

export async function getLPTokenValue(pool, coingeckoPrices) {
	let tokenABalanceWei = await getBalance(pool.chain, pool.tokenA.contract ?? pool.tokenA.wrappedContract, pool.stakeToken.contract);
	let tokenBBalanceWei = await getBalance(pool.chain, pool.tokenB.contract, pool.stakeToken.contract);

	let tokenABalance = await fromWeiByToken(tokenABalanceWei, pool.chain, pool.tokenA.contract);
	let tokenBBalance = await fromWeiByToken(tokenBBalanceWei, pool.chain, pool.tokenB.contract);

	let tokenAPrice = coingeckoPrices[pool.tokenA.coingeckoId];
	let tokenBPrice = coingeckoPrices[pool.tokenB.coingeckoId];

	let tokenAValue = tokenABalance * tokenAPrice;
	let tokenBValue = tokenBBalance * tokenBPrice;
	
	return tokenAValue + tokenBValue;
}

// ---- GETTER (USER) ----

export async function getDepositedAmount(pool, userAddress) {
	const stakingContract = new web3s[pool.chain].Contract(stakingAbi, Contracts.staking[pool.chain]);

    return stakingContract.methods.getDepositedAmount(pool.id, userAddress).call({from: userAddress});
}

export async function getClaimableAmount(pool, userAddress) {
	const stakingContract = new web3s[pool.chain].Contract(stakingAbi, Contracts.staking[pool.chain]);

    return stakingContract.methods.getClaimableAmount(pool.id, userAddress).call({from: userAddress});
}

// ---- GETTER (POOL) ----

export async function getPoolCount() {
	const stakingContract = await getCurrentStaking();

    return stakingContract.methods.getPools().call();
}

export async function getReflow(poolId) {
	const stakingContract = await getCurrentStaking();
	
    return stakingContract.methods.calcReflow(poolId).call();
}

export async function getPoolEndTime(poolId) {
	const stakingContract = await getCurrentStaking();
	
    return stakingContract.methods.getPoolEndTime(poolId).call();
}

export async function getPoolStakedAmount(poolId) {
	const stakingContract = await getCurrentStaking();
	
    return stakingContract.methods.getPoolStakedAmount(poolId).call();
}

export async function getPoolApy(poolId) {
	const stakingContract = await getCurrentStaking();
	
    return stakingContract.methods.getAPY(poolId).call();
}

export async function getPoolApyByPool(pool) {
	const stakingContract = new web3s[pool.chain].Contract(stakingAbi, Contracts.staking[pool.chain]);
	
    return stakingContract.methods.getAPY(pool.id).call();
}

export async function getRewardPerSecond(poolId) {
	const stakingContract = await getCurrentStaking();
	
    return stakingContract.methods.getRewardPerSecond(poolId).call();
}

export async function getRewards(poolId) {
	const stakingContract = await getCurrentStaking();
	
    return stakingContract.methods.getRewards(poolId).call();
}

export async function getAllRewardsPerShare(poolId) {
	const stakingContract = await getCurrentStaking();
	
    return stakingContract.methods.getAllRewardsPerShare(poolId).call();
}

export async function getRewardReserve(poolId) {
	const stakingContract = await getCurrentStaking();
		
    return stakingContract.methods.getRewardReserve(poolId).call();
}

export async function getStakedToken(poolId) {
	const stakingContract = await getCurrentStaking();
	
    return stakingContract.methods.getStakedToken(poolId).call();
}

export async function getRewardToken(poolId) {
	const stakingContract = await getCurrentStaking();
	
    return stakingContract.methods.getRewardToken(poolId).call();
}

export async function getLastRewardSecond(poolId) {
	const stakingContract = await getCurrentStaking();
	
    return stakingContract.methods.getLastRewardSecond(poolId).call();
}

export async function getClaimFee(poolId) {
	const stakingContract = await getCurrentStaking();
	
    return stakingContract.methods.getClaimFee(poolId).call();
}

export async function getDaoWallet(poolId) {
	const stakingContract = await getCurrentStaking();
	
    return stakingContract.methods.getDaoWallet(poolId).call();
}

export async function getWithdrawLockTime(poolId) {
	const stakingContract = await getCurrentStaking();
	
    return stakingContract.methods.getWithdrawLockTime(poolId).call();
}

// ---- SETTER (POOL) ----

export async function setClaimFee(poolId, fee) {
	const stakingContract = await getCurrentStaking();
	
	return stakingContract.methods.setClaimFee(poolId, fee).send(getMessage());
}

export async function setRPS(poolId, rps) {
	const stakingContract = await getCurrentStaking();
	
	return stakingContract.methods.changeRewardPerSecond(poolId, rps).send(getMessage());
}

export async function fundAndChangeRPS(poolId, rewardAmount, rps) {
	const stakingContract = await getCurrentStaking();
	
	return stakingContract.methods.fundAndChangeRPS(poolId, rewardAmount, rps).send(getMessage());
}

export async function fundIncreaseRPS(poolId, rewardAmount) {
	const stakingContract = await getCurrentStaking();
	
	return stakingContract.methods.fundIncreaseRPS(poolId, rewardAmount).send(getMessage());
}

export async function fundIncreasePeriod(poolId, rewardAmount) {
	const stakingContract = await getCurrentStaking();
	
	return stakingContract.methods.fundIncreasePeriod(poolId, rewardAmount).send(getMessage());
}

// ---- ADD POOL ----

export async function addPool(stakeTokenAddress, rewardTokenAddress, rewardsPerSecond, claimFee, daoWalletAddress, staticWithdrawTime, mode) {
	const stakingContract = await getCurrentStaking();
	
	return stakingContract.methods.setupPool(
		stakeTokenAddress,
		rewardTokenAddress,
		rewardsPerSecond,
		claimFee,
		daoWalletAddress,
		staticWithdrawTime,
		mode
	).send(getMessage());
}

// ---- INTERACT WITH POOL ----

export async function deposit(chain, poolId, tokenAddress, amount) {
	let stringAmount = amount.toString();
	let tokenContract = new provider.Contract(tokenAbi, tokenAddress);

	return approve(tokenContract, Contracts.staking[chain], stringAmount).then(async () => {
		const stakingContract = await getCurrentStaking();

		return stakingContract.methods.deposit(poolId, stringAmount).send(getMessage());
	});
}

export async function withdraw(poolId, amount) {
	let stringAmount = amount.toString();
	const stakingContract = await getCurrentStaking();

	return stakingContract.methods.withdraw(poolId, stringAmount).send(getMessage());
}

export async function claim(poolId, amount) {
	let stringAmount = amount.toString();
	const stakingContract = await getCurrentStaking();

	return stakingContract.methods.claim(poolId, stringAmount).send(getMessage());
}

export async function redeem(poolId) {
	const stakingContract = await getCurrentStaking();

	return stakingContract.methods.redeem(poolId).send(getMessage());
}

// ---- ZAPPER ----

export async function zap(amount, pool) {
	const chain = await getCurrentChain();
	const teneo = getToken(chain.nameId, "TEN");
	let path = [teneo.contract, pool.tokenB.contract];

	if (pool.zapToken) {
		path.push(pool.zapToken.contract ?? pool.zapToken.wrappedContract);
	}

	let teneoContract = new provider.Contract(tokenAbi, teneo.contract);
	
	return approve(teneoContract, Contracts.zapper[chain.nameId], amount).then(() => {
		const zapperContract = new provider.Contract(zapperAbi, Contracts.zapper[chain.nameId]);

		return zapperContract.methods.zapInTenLP(amount, pool.stakeToken.contract, path).send(getMessage());
	});
}