import { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Loader from './components/common/Loader';
import Container from "./components/layout/Container.js";
import './App.scss';

const Swap = lazy(() => import('./components/swap/Swap'));
const Farm = lazy(() => import('./components/farm/Farm'));
const Compound = lazy(() => import('./components/compound/Compound'));

const NotFound = lazy(() => import('./components/notFound/NotFound'));

export default function App() {
    return (
		<BrowserRouter>
			<Suspense fallback={<Loader/>}>
				<Routes>
					<Route exact path="/" element={<Navigate to="/swap"/>}/>
					<Route exact path="/swap" element={<Container component={<Swap/>}/>}/>
					<Route exact path="/farm" element={<Container component={<Farm/>}/>}/>
					<Route exact path="/compound" element={<Container component={<Compound/>}/>}/>
					
					<Route path="*" element={NotFound}/>
				</Routes>
			</Suspense>
		</BrowserRouter>
    );
}