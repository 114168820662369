import styles from "./Main.module.scss";
import { CoingeckoPricesProvider } from "../contexts/CoingeckoPricesProvider.js";
import { IsConnectedProvider } from "../contexts/IsConnectedProvider.js";
import { LpTokenValuesProvider } from "../contexts/LpTokenValuesProvider.js";
import Header from "./Header.js";
import Footer from "./Footer.js";

export default function Main({children}) {
    return (
        <CoingeckoPricesProvider>
			<IsConnectedProvider>
				<LpTokenValuesProvider>
					<Header/>
					
					<main className={styles.main}>
						<div className={styles.container}>
							{children}
						</div>
					</main>

					<Footer/>
				</LpTokenValuesProvider>
			</IsConnectedProvider>
		</CoingeckoPricesProvider>
    );
}