import styles from './Footer.module.scss';

export default function Footer() {
	return (
		<footer className="footer wf-section">
			<div data-collapse="none" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className={"navbar-footer w-nav " + styles.new_footer}>
				<div className="nav-container-footer">
					<a href="https://teneo.finance"><img src="images/Teneo_Logo_red.svg" loading="lazy" alt="" className={"teneo-logo-footer " + styles.footer_logo}/></a>

					<div className="w-nav-button">
						<div className="icon w-icon-nav-menu"></div>
					</div>

					<div className={styles.social_container_2}>
						<div>
							<a href={"https://twitter.com/TeneoFinance"} target="_blank" rel="noopener noreferrer">
								<div className={styles.social_icon_container}>
									<img className={styles.social_icon} src={"/images/socials/twitter.svg"} alt={"/images/socials/twitter.svg"}/>
								</div>
							</a>
						</div>
						<div>
							<a href={"https://t.me/TeneoFinance"} target="_blank" rel="noopener noreferrer">
								<div className={styles.social_icon_container}>
									<img className={styles.social_icon} src={"/images/socials/telegram.svg"} alt={"/images/socials/telegram.svg"}/>
								</div>
							</a>
						</div>
						<div>
							<a href={"https://medium.com/@teneofinance"} target="_blank" rel="noopener noreferrer">
								<div className={styles.social_icon_container}>
									<img className={styles.social_icon} src={"/images/socials/medium.svg"} alt={"/images/socials/medium.svg"}/>
								</div>
							</a>
						</div>
						<div>
							<a href={"https://www.reddit.com/r/TeneoFinance"} target="_blank" rel="noopener noreferrer">
								<div className={styles.social_icon_container}>
									<img className={styles.social_icon} src={"/images/socials/reddit.svg"} alt={"/images/socials/reddit.svg"}/>
								</div>
							</a>
						</div>
						<div>
							<a href={"https://discord.gg/GVvGyVvkhs"} target="_blank" rel="noopener noreferrer">
								<div className={styles.social_icon_container}>
									<img className={styles.social_icon} src={"/images/socials/discord.svg"} alt={"/images/socials/discord.svg"}/>
								</div>
							</a>
						</div>
						<div>
							<a href={"https://www.youtube.com/channel/UCgSGrkfCEQuva6dei24zwBg"} target="_blank" rel="noopener noreferrer">
								<div className={styles.social_icon_container}>
									<img className={styles.social_icon} src={"/images/socials/youtube.svg"} alt={"/images/socials/youtube.svg"}/>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}