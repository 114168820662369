const Chains = [
	{
		id: 56,
		hex: "0x38",
		url: "https://bsc-dataseed.binance.org",
		name: "Binance Smart Chain",
		nameId: "bsc",
		currency: {
			name: "Binance Coin",
			symbol: "BNB",
			decimals: 18,
		},
		blockExplorer: "https://bscscan.com",
	},
	{
		id: 250,
		hex: "0xFA",
		url: "https://rpc.ftm.tools",
		name: "Fantom",
		nameId: "ftm",
		currency: {
			name: "Fantom",
			symbol: "FTM",
			decimals: 18,
		},
		blockExplorer: "https://ftmscan.com",
	}
];

export function getChains() {
	return Chains;
}

export function getChain(nameId) {
	return Chains.find(chain => chain.nameId === nameId);
}

export function getChainById(id) {
	return Chains.find(chain => chain.id === id);
}