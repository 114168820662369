import fromExponential from "from-exponential";
import Web3 from "web3";

import { getChains, getChain, getChainById } from "../../data/chains.js";

import tokenAbi from "../../data/abis/token.json";

export const web3s = getChains().reduce((web3s, chain) => {
	web3s[chain.nameId] = (new Web3(chain.url)).eth;
	
	return web3s;
}, {});
export const defaultChain = "bsc";
export const cutDecimals = 2;

export let provider = Web3.givenProvider ? (new Web3(Web3.givenProvider)).eth : undefined;
export let currentAccount = undefined;


// ---- RELOAD ON CHANGE ----

if (window.ethereum) {
    window.ethereum.on("chainChanged", () => {
        window.location.reload();
    });
    window.ethereum.on("accountsChanged", () => {
        window.location.reload();
    });
}

// ---- ACCOUNT ----

export async function getAccount() {
	if (provider) {
		let accounts = await provider.getAccounts();

		currentAccount = accounts[0];

		return currentAccount;
	}
}

export async function requestAccount() {
	if (provider) {
		let accounts = await provider.requestAccounts();

		currentAccount = accounts[0];

		return currentAccount;
	}
}

export function isMetaMaskConnected() {
	let metaMaskProvider = Web3.givenProvider;

	return metaMaskProvider !== undefined && metaMaskProvider !== null;
}


// ---- SEND ----

export function getMessage(extraMessage) {
	return {
		from: currentAccount,
		maxPriorityFeePerGas: null,
		maxFeePerGas: null,
		...extraMessage
	};
}

// ---- GET CHAIN ----

export async function getChainId() {
	if (provider) {
		return provider.net.getId();
	}
}

// ---- GET EXTERNAL CHAIN DATA ----

export async function getExternalChainData() {
	if (provider) {
		let response = await fetch("https://chainid.network/chains.json");
		let json = await response.json();
	
		let chainId = await getChainId();
		let chainData = json.find(chain => chain.chainId === chainId);
	
		return chainData;
	}
}

export async function getChainName() {
	if (provider) {
		let chainData = await getExternalChainData();

		return chainData.chain;
	}
}

// ---- GET INTERNAL CHAIN DATA ----

export async function getCurrentChain() {
	if (provider) {
		const chainId = await getChainId();

		return getChainById(chainId);
	} else {
		return getChain(defaultChain);
	}
}

export async function getInternalChainData() {
	if (provider) {
		const chainId = await getChainId();
		
		return getChainById(chainId);
	}
}

export async function isChainSupported() {
	if (provider) {
		let currentId = await getChainId();
		let supportedIds = getChains().map(chain => chain.id);

		return supportedIds.includes(currentId);
	}
}

// ---- GET BALANCE ----

export async function getBalance(chain, tokenAddress, ofAddress) {
	if (tokenAddress) {
		let tokenContract = new web3s[chain].Contract(tokenAbi, tokenAddress);

		return tokenContract.methods.balanceOf(ofAddress).call();
	} else {
		return web3s[chain].getBalance(ofAddress);
	}
}

export async function getOwnBalance(chain, tokenAddress) {
    return getBalance(chain, tokenAddress, currentAccount);
}

export async function getOwnFormattedBalance(chain, tokenAddress) {
	let balance = await getBalance(chain, tokenAddress, currentAccount);
	let number = await fromWeiByToken(balance, chain, tokenAddress);

	return number;
}

export function getCutBalance(balance) {
	let balanceString = balance.toString();
    let balanceSplit = balanceString.split(".");
    let cutBalance = balanceSplit[0];

    if (balanceSplit.length === 2) {
        cutBalance += "." + balanceSplit[1].substr(0, cutDecimals);
    }

    if (balanceString !== cutBalance) {
        cutBalance += "...";
    }

    return cutBalance;
}

// ---- WEI ----

export async function fromWei(wei, decimals) {
	if (wei.toString().includes("e")) {
		wei = fromExp(wei);
	}

	if (typeof wei !== "string") {
        wei = wei.toString();
    }

    let commaPosition = wei.length - decimals;
	let number;

	if (wei !== "0") {
		if (commaPosition > 0) {
			number = [wei.slice(0, commaPosition), ".", wei.slice(commaPosition)].join('').replace(/0+$/, "");
		} else if (commaPosition === 0) {
			number = ("0." + wei).replace(/0+$/, "");
		} else {
			number = ("0." + "0".repeat(Math.abs(commaPosition)) + wei).replace(/0+$/, "");
		}

        if (number.endsWith(".")) {
            number = number.slice(0, -1);
        }
	} else {
		number = "0";
	}

    return number;
}

export async function toWei(number, decimals) {
    let numberString = number.toString().replace(/^0+/, '');
	let numberArray = numberString.split(".");
	let numberFormat = numberArray.join("").replace(/^0+/, '');

	let zeroCount;
	if (numberArray.length === 1) {
		zeroCount = decimals;
	} else {
		zeroCount = decimals - numberArray[1].length;
	}

	return numberFormat + "0".repeat(zeroCount);
}

export async function fromWeiByToken(wei, chain, tokenAddress) {
    let decimals = await getDecimals(chain, tokenAddress);
    let number = await fromWei(wei, decimals);

    return number;
}

export async function toWeiByToken(number, chain, tokenAddress) {
    let decimals = await getDecimals(chain, tokenAddress);
    let wei = await toWei(number, decimals);

    return wei;
}

export async function getDecimals(chain, tokenAddress) {
	if (tokenAddress) {
		let tokenContract = new web3s[chain].Contract(tokenAbi, tokenAddress);

		return tokenContract.methods.decimals().call().then((result) => {
			return parseInt(result);
		});
	} else {
		return 18;
	}
}

export function fromExp(number) {
	return fromExponential(number);
}

export async function toBn(number) {
	return Web3.utils.toBN(number);
}

// ---- APPROVE ----

export async function getAllowance(baseContract, toApproveContract) {
	return baseContract.methods.allowance(currentAccount, toApproveContract).call();
}

export async function approve(baseContract, toApproveContract, amount) {
	let allowance = await getAllowance(baseContract, toApproveContract);

	let allowanceBn = await toBn(allowance);
	let amountBn = await toBn(amount);

	if (allowanceBn.lt(amountBn)) {
		return baseContract.methods.approve(toApproveContract, amountBn).send(getMessage());
	} else {
		return Promise.resolve();
	}
}

// ---- METAMASK - CHAIN ----

export async function switchChain(chain) {
	return window.ethereum.request({
		method: "wallet_switchEthereumChain",
		params: [{ chainId: chain.hex }],
	}).catch((error) => {
		let errorCode = 4902;

		if (error && (error.code === errorCode || error.data?.originalError?.code === errorCode)) {
			return addChain(chain);
		}
	});
}

async function addChain(chain) {
	return window.ethereum.request({
		method: "wallet_addEthereumChain",
		params: [{
			chainId: chain.hex,
			chainName: chain.name,
			nativeCurrency: chain.currency,
			rpcUrls: [chain.url],
			blockExplorerUrls: [chain.blockExplorer],
		}],
	});
}

// ---- METAMASK - TOKEN ----

export async function addTokenToMetamask(token) {
    return watchAsset(token.contract, token.symbol, token.decimals);
}

export async function addLPTokenToMetamask(token) {
    return watchAsset(token.contract, token.chain.lpSymbol, token.decimals);
}

async function watchAsset(contract, symbol, decimals) {
	return window.ethereum.request({
		method: "wallet_watchAsset",
		params: {
			type: "ERC20",
			options: {
				address: contract,
				symbol: symbol,
				decimals: decimals
			},
		},
	});
}